import React from "react";
import styles from "./ArrowButton.module.scss";
import classNames from "classnames"


export default function ArrowButton({ title, icon, blue_icon, action }) {
  return (
    <a className={classNames(styles.ArrowButton, { [styles['bg-blue']]: blue_icon })} href={action}>
      <p>{title}</p>
      <div className={classNames(styles.Icon, { [styles['bg-icon-blue']]: blue_icon })}>{icon}</div>
    </a>
  );
}
