import React, { useState, useEffect, useContext } from "react";
import styles from "./StepByStep.module.scss"
import ProgressBar from "../../components/ProgressBar/ProgressBar"
import { useParallax } from "react-scroll-parallax";
import { LanguageContext } from "../../context/LanguageContext";
import content from "./content.json"

const imagesPreload = ['https://icfcapao.com.br/img/01.gif', 'https://icfcapao.com.br/img/02.gif', 'https://icfcapao.com.br/img/03.gif'];
imagesPreload.forEach((image) => {
    const newImage = new Image();
    newImage.src = image;
    window[image] = newImage;
});

const StepByStep = () => {
    const [scrollProgress, setScrollProgress] = useState(0);
    const [step, setStep] = useState("01");
    const { language } = useContext(LanguageContext)

    const parallax = useParallax({
        onEnter: (e) => {
        },
        onExit: (e) => {
        },
        onProgressChange: (e) => {
            setScrollProgress(e);
        }
    });

    useEffect(() => {
        if (scrollProgress <= 0.4) {
            setStep("01")
        }
        if (scrollProgress >= 0.4 && scrollProgress <= 0.6) {
            setStep("02")
        }
        if (scrollProgress >= 0.6 && scrollProgress <= 0.9) {
            setStep("03")
        }
    }, [scrollProgress])

    return (
        <div ref={parallax.ref} className={styles.Section}>
            <div className={styles.SectionSticky}>
                <div className={styles.ProgressBars}>
                    <ProgressBar min={0} max={0.2} val={scrollProgress} />
                    <ProgressBar min={0.2} max={0.4} val={scrollProgress} />
                    <ProgressBar min={0.4} max={0.6} val={scrollProgress} />
                    {/* <ProgressBar min={0.6} max={0.8} val={scrollProgress} />
                    <ProgressBar min={0.8} max={1} val={scrollProgress} /> */}
                </div>
                <p className={styles.StepTitle}>
                    {step + ". " + content.steps[language][step]}
                </p>
                <div className={styles.GifContainer}>
                    {step === "01" && <img src={'https://icfcapao.com.br/img/01.gif'} alt="Macbook showing screenshots from platform" />}
                    {step === "02" && <img src={'https://icfcapao.com.br/img/02.gif'} alt="Macbook showing screenshots from platform" />}
                    {step === "03" && <img src={'https://icfcapao.com.br/img/03.gif'} alt="Macbook showing screenshots from platform" />}
                </div>
            </div>
        </div>
    )
}

export default StepByStep;