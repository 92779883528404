import React, { useContext } from "react";
import SimpleButton from "../SimpleButton/SimpleButton.jsx";
import styles from "./PriceResult.module.scss"
import classNames from "classnames";
import { BudgetContext } from "../../context/BudgetContext.jsx";
import content from "./content.json"
import { LanguageContext } from "../../context/LanguageContext.jsx";
import { Backdrop, CircularProgress } from "@mui/material";

export default function PriceResult({ handleOpenModal }) {
    const { totalPrice, budgetPayload } = useContext(BudgetContext);
    const { language } = useContext(LanguageContext)
    const [loadingBudget, setLoadingBudget] = React.useState(false);

    const handleClose = () => {
        setLoadingBudget(false);
    };
    const handleOpen = () => {
        setLoadingBudget(true);
    };

    const createBudgetInDB = () => {
        if (totalPrice > 0) {
            handleOpen();
            var url = process.env.REACT_APP_SERVER_URL + "/Budget/submit-budget";
            
            fetch(url, {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(budgetPayload),
            }).then((response) => {
                handleClose();
                return response.json()
            }).then((data) => {
                window.sessionStorage.setItem("token-budget", data.token);
                handleOpenModal();
            })
        }
    }

    return (
        <div className={styles.ResultContainer}>
            <Backdrop
                sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
                open={loadingBudget}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

            <p className={styles.PriceTitle}>
                {content.price_title[language]}
            </p>

            <h2 className={classNames(styles.PriceText,
                {
                    [styles.large]: (totalPrice < 100000),
                    [styles.medium]: (totalPrice >= 10000 && totalPrice <= 1000000),
                    [styles.small]: (totalPrice >= 1000000 && totalPrice <= 100000000),
                    [styles.smallest]: (totalPrice >= 100000000),
                })}> R$ {totalPrice === 0 ? "--" : totalPrice.toLocaleString()} </h2>
            <SimpleButton title={content.button_send_budget[language]} onClick={createBudgetInDB} variant={totalPrice > 0 ? "contained" : "disabled"} size="large" />
        </div >
    )
}