import React, { useContext } from 'react';
import styles from "./Navbar.module.scss";
import SimpleButton from "../SimpleButton/SimpleButton.jsx";
import { ReactComponent as RIcon } from '../../icons/r-vector.svg';
import { LanguageContext } from '../../context/LanguageContext.jsx';
import content from './content.json';
import classNames from 'classnames';

export default function Navbar({ page = "home" }) {
  const { language, changeLanguage } = useContext(LanguageContext);

  return (
    <div className={styles.Navbar}>
      <div className={styles.LangSwitch}>
        <p className={classNames({ [styles.LangSelected]: language === "pt" })} onClick={(e) => { changeLanguage('pt') }}>PT</p>
        |
        <p className={classNames({ [styles.LangSelected]: language === "en" })} onClick={(e) => { changeLanguage('en') }}>EN</p>
      </div>
      <div className={styles.NavbarWrapper}>
        <div className={styles.Logo} onClick={() => { window.location.href = "/" }}>
          <RIcon
            className={styles.LogoImg}
          />
          <p>
            Dynamic Workflow <br /> for Creative Industry
          </p>
        </div>

        <div className={styles.Links}>
          <RIcon
            className={styles.LogoMobile}
          />
          {page === "home" &&
            < SimpleButton title={content.buttonHome[language]} onClick={() => { window.open("/create", "_self") }} />}
          {page === "budget" &&
            < SimpleButton title={content.buttonBudget[language]} onClick={() => { window.open("/", "_self") }} />}
        </div>
      </div>
    </div>
  );
}
