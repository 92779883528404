import React from "react";
import Header from "../sections/Header/Header.jsx";
import About from "../sections/About/About.jsx";
import AboutVideo from "../sections/AboutVideo/AboutVideo.jsx";
import Formats from "../sections/Formats/Formats.jsx";
import Benefits from "../sections/Benefits/Benefits.jsx";
import Footer from "../sections/Footer/Footer.jsx";
import { ParallaxProvider } from 'react-scroll-parallax';
import StepByStep from "../sections/StepByStep/StepByStep.jsx";


export default function Institucional(){
    return (
        <ParallaxProvider>
            <div className="App">
                <Header />
                <AboutVideo />
                <About />
                <StepByStep />
                <Formats />
                <Benefits />
                <Footer />
            </div>
        </ParallaxProvider>
    )
}